@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-Light.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-Light.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-Light.woff2') format('woff2');
  font-weight: 270;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0025-00FF;
}

@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-LightItalic.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-LightItalic.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-LightItalic.woff2') format('woff2');
  font-weight: 270;
  font-style: italic;
  font-display: swap;
  unicode-range: U+0025-00FF;
}

@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-Regular.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-Regular.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-Regular.woff2') format('woff2');
  font-weight: 370;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0025-00FF;
}

@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-Italic.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-Italic.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-Italic.woff2') format('woff2');
  font-weight: 370;
  font-style: italic;
  font-display: swap;
  unicode-range: U+0025-00FF;
}

@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-Medium.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-Medium.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-Medium.woff2') format('woff2');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0025-00FF;
}

@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-MediumItalic.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-MediumItalic.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-MediumItalic.woff2') format('woff2');
  font-weight: 450;
  font-style: italic;
  font-display: swap;
  unicode-range: U+0025-00FF;
}

@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-Bold.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-Bold.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-Bold.woff2') format('woff2');
  font-weight: 550;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0025-00FF;
}

@font-face {
  font-family: 'Universal';
  src: url('/fonts/Universal/UniversalSans-Display-BoldItalic.ttf') format('ttf'),
    url('/fonts/Universal/UniversalSans-Display-BoldItalic.woff') format('woff'),
    url('/fonts/Universal/UniversalSans-Display-BoldItalic.woff2') format('woff2');
  font-weight: 550;
  font-style: italic;
  font-display: swap;
  unicode-range: U+0025-00FF;
}
