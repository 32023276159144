:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #f1f7fa;
  --color-blue-100: #dadfe3;
  --color-blue-200: #ebf3f8;
  --color-blue-300: #c2dbea;
  --color-blue-500: #009fd9;
  --color-blue-600: #0077cd;
  --color-blue-700: #0e578c;
  --color-blue-900: #072b45;
  --color-orange-500: #e37222;
  --color-red-500: #d10031;

  --color-blue-900--40: rgba(7, 43, 70, 0.4);
}
