:root {
  --size-4: 4px;
  --size-8: 8px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-36: 36px;
  --size-40: 40px;
  --size-48: 48px;
  --size-52: 52px;
  --size-64: 64px;
  --size-80: 80px;
  --size-100: 100px;
  --size-125: 125px;
  --size-150: 150px;
  --size-175: 175px;
  --size-200: 200px;
  --size-300: 300px;
}
