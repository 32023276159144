:root {
  --duration-150: 0.15s;
  --duration-250: 0.25s;
  --duration-350: 0.35s;
  --duration-400: 0.4s;
  --duration-450: 0.45s;
  --duration-500: 0.5s;
  --duration-600: 0.6s;
  --duration-700: 0.7s;
  --duration-850: 0.85s;
  --duration-900: 0.9s;
  --duration-1500: 1.5s;
  --duration-2000: 2s;
  --duration-3000: 3s;

  --ease: ease;
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
  --ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
  --ease-out-circ: cubic-bezier(0.57, 0.4, 0.55, 1.17);
}
