:root {
  --font-family-base: 'Universal', sans-serif;
  --font-family-paragraph: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
  --font-family-alt: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;

  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-700: 700;

  --line-height-heading: 0.82;
  --line-height-normal: 1;
  --line-height-block: 1.15;
  --line-height-paragraph: 1.5;
  --line-height-text: 1.6;

  --letter-spacing-block: -0.4px;
  --letter-spacing-heading: -0.8px;

  /* Font sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-38: 38px;
  --font-size-40: 40px;
  --font-size-50: 50px;
  --font-size-70: 70px;
  --font-size-100: 100px;
  --font-size-120: 120px;
  --font-size-140: 140px;
  --font-size-200: 200px;
  --font-size-230: 230px;
  --font-size-240: 240px;
  --font-size-270: 270px;

  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-16-20: clamp(16px, calc(16px + 4 * var(--m)), 20px);
  --font-size-18-20: clamp(18px, calc(18px + 2 * var(--m)), 20px);
  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--m)), 22px);
  --font-size-20-26: clamp(20px, calc(20px + 6 * var(--m)), 26px);
  --font-size-22-32: clamp(22px, calc(22px + 10 * var(--m)), 32px);
  --font-size-32-38: clamp(32px, calc(32px + 6 * var(--m)), 38px);
  --font-size-24-40: clamp(24px, calc(24px + 16 * var(--m)), 40px);
  --font-size-24-70: clamp(24px, calc(24px + 46 * var(--m)), 70px);
  --font-size-32-40: clamp(32px, calc(32px + 8 * var(--m)), 40px);
  --font-size-32-46: clamp(32px, calc(32px + 14 * var(--m)), 46px);
  --font-size-32-70: clamp(32px, calc(32px + 38 * var(--m)), 70px);
  --font-size-40-70: clamp(40px, calc(40px + 30 * var(--m)), 70px);
  --font-size-40-100: clamp(40px, calc(40px + 60 * var(--m)), 100px);
  --font-size-50-100: clamp(50px, calc(50px + 50 * var(--m)), 100px);
  --font-size-32-120: clamp(32px, calc(32px + 88 * var(--m)), 120px);
  --font-size-50-150: clamp(50px, calc(50px + 100 * var(--m)), 150px);
  --font-size-70-100: clamp(70px, calc(70px + 30 * var(--m)), 100px);
  --font-size-70-120: clamp(70px, calc(70px + 50 * var(--m)), 120px);
  --font-size-70-140: clamp(70px, calc(70px + 70 * var(--m)), 140px);
  --font-size-70-150: clamp(70px, calc(70px + 80 * var(--m)), 150px);
  --font-size-70-200: clamp(70px, calc(70px + 130 * var(--m)), 200px);
  --font-size-70-230: clamp(70px, calc(70px + 160 * var(--m)), 230px);
  --font-size-50-240: clamp(50px, calc(50px + 190 * var(--m)), 240px);
  --font-size-70-240: clamp(70px, calc(70px + 170 * var(--m)), 240px);
  --font-size-70-270: clamp(70px, calc(70px + 200 * var(--m)), 270px);
}
